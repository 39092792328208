import { Typography } from '@material-ui/core'
import { PageProps } from 'gatsby'
import Navbar from 'manage-tritag/components/navbar'
import MobileNavbar from 'manage-tritag/components/navbar/mobile'
import { useMediaQuery } from 'react-responsive'

const LoginPage = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Typography variant="h4" component="h1" gutterBottom color="primary">
        LoginPage
      </Typography>
    </>
  )
}

export default LoginPage
